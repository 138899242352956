import { Injectable, OnInit } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable({
  providedIn: "root",
})
export class AdminGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}

  canActivate() {
    let usuario = JSON.parse(localStorage.getItem("usuario"));
    if (
      usuario !== null &&
      usuario.tipoUsuario === "5e6d26147ada7315a47f47de"
    ) {
      return true;
    } else if (usuario === null) {
      this.router.navigate(["login"]);
      return false;
    } else if (usuario.nivel !== 0) {
      return false;
    }
  }
}
