import { Injectable } from "@angular/core";
import { Router, CanActivate } from "@angular/router";
import { AuthService } from "./../services/auth.service";
import { PublicModule } from "../public.module";

@Injectable({
  providedIn: "root",
})
export class LoggedInGuard implements CanActivate {
  constructor(public authService: AuthService, public router: Router) {}
  canActivate() {
    if (this.authService.loggedIn()) {
      this.router.navigate(["dashboard/home"]);
      return false;
    } else {
      return true;
    }
  }
}
